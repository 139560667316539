import Colors from 'api/style/Colors.ts';

export const ColorRegex = {
    hex: /^#(?=.{3}|.{6})([0-9a-fA-F]{1,2})([0-9a-fA-F]{1,2})([0-9a-fA-F]{1,2})$/,
    rgb: /^rgb\((((?:0|1)\d?\d)|(?:2[0-5]{2})), ?(((?:0|1)\d?\d)|(?:2[0-5]{2})), ?(((?:0|1)\d?\d)|(?:2[0-5]{2}))\)$/,
    any: /^((#(([0-9a-fA-F]){3}){1,2})|(rgb\((((0|1)?\d?\d)|(2[0-5]{2}))(,(((0|1)?\d?\d)|(2[0-5]{2}))){2}\)))$/,
};

export function ColorHexify(color: string | undefined, fallback?: string) {
    color ??= fallback;

    if (!color) {
        return undefined;
    }

    if (ColorRegex.hex.test(color)) {
        return color.toLowerCase();
    }

    if (ColorRegex.rgb.test(color)) {
        return ColorRegex.rgb.exec(color)!.slice(1).map((x) => parseInt(x)).map((x) => x.toString(16).padStart(2, '0'))
            .join('').replace(/^/, '#').toLowerCase();
    }

    const [c, s] = color.split('-');

    for (const [k, v] of Object.entries(Colors)) {
        if (c != k) {
            continue;
        }

        if (typeof v === 'string') {
            return v;
        }

        if (typeof v === 'object' && parseInt(s) in v) {
            return v[parseInt(s) as keyof typeof v];
        }
    }

    console.warn(`"${color}" is not a valid CSS color. Falling back to "${fallback}".`);
    return fallback;
}

export default function Colorify(color: string | undefined, fallback?: string) {
    color ??= fallback;

    if (!color) {
        return undefined;
    }

    if (
        ColorRegex.any.test(color)
    ) {
        return `[${ColorHexify(color)}]`;
    }

    const tokens = color.split('-');
    if (tokens.length <= 2) {
        const t = tokens[0];
        const s = tokens[1];
        if (t in Colors) {
            if (typeof Colors[t as keyof typeof Colors] === 'string') {
                return color;
            }

            if (
                typeof Colors[t as keyof typeof Colors] === 'object' &&
                s in (Colors[t as keyof typeof Colors] as object)
            ) {
                return color;
            }
        }
    }

    console.warn(`"${color}" is not a valid CSS color or a TailwindCSS color. Falling back to "${fallback}".`);

    return fallback;
}
